import { graphql } from "gatsby";
import React from "react";
import { InfoPage } from "../components/InfoPage";
import { Layout } from "../components/Layout";
import { useBookAppointment } from "../components/Menu/hooks/useBookAppointment";
import Metadata from "../components/Metadata";
import { RichText } from "../components/RichText";
import { Text, Body, A } from "../components/Typography";

const MadeToMeasure = ({ data }) => {
    const { openBookAppointment } = useBookAppointment();
    const { image, title, content } = data.contentfulMadeToMeasurePage;
    return (
        <Layout>
            <Metadata title="Made to Measure" desc="" />
            <InfoPage
                title={title}
                image={{
                    src: image.resize.src,
                }}
            >
                <RichText>{content}</RichText>
                <br />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Text as="button" onClick={() => openBookAppointment()}>
                        Book an Appointment
                    </Text>
                </div>
            </InfoPage>
        </Layout>
    );
};

export default MadeToMeasure;

export const query = graphql`
    query MadeToMeasurePageQuery {
        contentfulMadeToMeasurePage {
            id
            title
            # description {
            #     description
            # }
            image {
                resize(width: 1000) {
                    src
                }
            }
            content {
                raw
            }
        }
    }
`;
